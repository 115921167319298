// <-- 내 정보 관련 --> //
export const CHECK_EMAIL_API = '/account/check_email_contains' // 이메일 중복 체크
export const GET_SUBSCRIPTION_TERM_API = '/generic/get_subscription_terms' // 약관 기입
export const CREATE_ACCOUNT_API = '/account/create_account'  // 회원가입
export const CREATE_ACCOUNT_AND_AUTO_LOGIN_API = '/account/create_auto_login'  // 회원가입 후 자동 로그인
export const LOGIN_API = '/account/login_account'  // 로그인
export const CHANGE_PASSWORD_API = '/account/change_password'  // 비밀번호 변경
export const GET_MY_INFO_API = '/account/my_info' // 내정보 가져오기
export const EDIT_MY_INFO_API = '/account/edit_my_info' // 내정보 수정
export const UNREGISTER_ACCOUNT_API = '/account/unregist_user' // 계정 삭제
export const GET_MY_NOTIFICATION_API = '/notification/my_notification_data' // 내 알람 정보 가져오기

// <-- 게시판 관련 --> //
export const GET_BOARD_LIST_API = '/generic/board_list' // 게시판 목록 (공지사항, 1대1문의, FnQ)
export const GET_BOARD_DETAIL_API = '/generic/board_content_detail' // 게시판 상세보기
export const BOARD_WRITE_API = '/generic/board_write' // 게시글 작성하기
export const BOARD_EDIT_API = '/generic/board_edit' // 게시글 수정
export const BOARD_DELETE_API = '/generic/board_delete' // 게시글 삭제

// <-- 즐겨찾기 지점 관련 --> //
export const ADD_MY_STORE_API = '/storeinfo/add_my_store' // 지점 즐겨찾기
export const GET_MY_STORE_LIST_API = '/storeinfo/my_store_list' // 즐겨찾는 지점 조회
export const SET_DEFAULT_STORE_API = '/storeinfo/set_default_store' // 기본지점으로 지정
export const DELETE_MY_FAVORITE_STORE_API = '/storeinfo/delete_mystore'  // +/{userId}/{sessionCode}/{storeCode} 즐겨찾기 지점목록에서 삭제

// <-- 점주 관련 --> //
// export const CUSTOMER_LIST_OF_MY_STORE_API = '/storeinfo/list_belong_users'  // 내 지점 고객리스트
// export const ALL_CUSTOMER_LIST_API = '/account/all_user_list'  // 전체 고객리스트
export const CUSTOMER_LIST_OF_MY_STORE_API = '/storeinfo/list_belong_users_with_order'  // 내 지점 고객리스트
export const ALL_CUSTOMER_LIST_API = '/account/all_user_list_with_order'  // 전체 고객리스트


export  const  ALL_CUSTOMER_LIST_API_EX = '/account/all_user_list_with_order_except_cs'  // 전체 고객리스트
export const GET_MY_STORE_INFO_API = '/storeinfo/store_info' // 점주의 본인 지점 정보 가져오기
export const EDIT_MY_STORE_INFO_API = '/storeinfo/edit_store_Info' // 점주의 지점 정보 수정
export const CONFIRM_FOR_MY_STORE_USER_API = '/storeinfo/approval_confirm_for_user' // 점주의 고객 승인 처리
export const STORE_AGGREGATE_TOTAL_API = '/report/store_aggregate_total'  // 고객 가계부 상위 테이블 데이터 가져오기
export const STORE_AGGREGATE_LIST_API = '/report/store_aggregate_list'  // 고객 가계부 하위 테이블 데이터 가져오기
export const AGGREGATE_ITEM_API = '/report/aggregate_item'  // 폳더버튼 클릭 시 티켓 데이터 가져오기
export const SEND_DIRECT_MESSAGE_TO_CUSTOMER = '/account/send_message_from_storeowner'  // 고객들에게 쪽지 보내기

// <-- 관리자 관련 --> //
export const CREATE_STORE_API = '/storeinfo/add_store' // 지점 등록
export const ALL_STORE_LIST_API = '/storeinfo/store_list'  // 지점 목록
export const ALL_STORE_LIST_SIMPLE_API = '/storeinfo/store_list_simple'  // 지점 목록 심플
export const ALL_STORE_LIST_WITH_CONDITION_API ='/storeinfo/store_list_with_condition'  // 지점 목록 + 검색조건
export const ADMIN_GET_STORE_INFO_API = '/storeinfo/store_info_for_admin' // 관리자의 지점 정보 가져오기
export const ADMIN_EDIT_STORE_INFO_API = '/storeinfo/modify_store_information_by_admin' // 관리자의 지점 정보 수정
export const CONNECT_BONA_TO_STORE_API = '/storeinfo/set_store_owner'  // 지점에 점주 계정 지정
export const RE_LOGIN_ACCOUNT_API = '/account/relogin_account'  // 로그인 갱신
export const ADMIN_AGGREGATE_TOTAL_API = '/report/admin_aggregate_total'  // 집계 페이지 상위 테이블 데이터 가져오기
export const ADMIN_AGGREGATE_TABLE_LIST_API = '/report/admin_aggregate_list'  // 집계 페이지 하위 테이블 데이터 가져오기
export const ADMIN_AGGREGATE_TICKET_LIST_API = ''  // 집계 페이지 티켓 데이터 가져오기 FIXME: API받으면 수정
export const ADMIN_CALCULATE_LIST_FOR_SA_API = '/report/total_reports_for_superadmin'  // 정산페이지 데이터 가져오기
export const ADMIN_GAME_MANAGEMENT_LIST_API = '/report/game_report_list'  // 경기관리 페이지 데이터 가져오기 (고정금액 ONLY)
export const ADMIN_GAME_MANAGEMENT_LIST_WITH_CURRENT_AMOUNT_API = '/report/game_report_list2'  // 경기관리 페이지 데이터 가져오기 (고정금액 & 변동금액)
export const ADMIN_GAME_MANAGEMENT_EDIT_DATA_API = '/admin/set_game_result'  // 경기관리 - 경기 관리 모달에서 데이터 변경 (스코어, LOCK 설정, 적특처리)
export const ADMIN_USER_EDIT_API = '/admin/admin_user_edit'  // 관리자의 고객 정보 수정
export const ADMIN_SYNC_GAME_REPORT = '/report/update_game_result'  // 사이트 데이터 동기화
export const LOGIN_USER_ACCOUNT_BY_ADMIN = '/account/login_account_by_admin'  // 관리자가 유저계정으로 로그인
export const LOGIN_STORE_ACCOUNT_BY_ADMIN = '/account/login_storeaccount_by_admin'  // 관리자가 점주계정으로 로그인
export const ADMIN_GET_GAME_ROUND_LIST_API = '/admin/get_game_round_list'  // 회차관리 리스트 받아오기

// <-- 경기 관련 --> //
export const LIST_OF_GAME_DATA_API = '/gameinfo/game_info_list' // 게임 데이터 가져오기
export const MY_GAME_DATA_API = '/gamedata/my_game_data_list'  // 게임데이터 조회 (카트, 의뢰)
export const UPDATE_TICKET_MEMO_API = '/gamedata/update_game_data_memo' // 티켓 메모 수정
export const REGIST_NEW_GAME_TICKET_API = '/gamedata/create_game_data_status_list' // 게임 티켓 등록(카트담기, 출력의뢰)
export const UPDATE_GAME_TICKET_API = '/gamedata/update_game_data_status' // 게임 티켓 이동 (카트 -> 출력의뢰)
export const DELETE_GAME_TICKET_API = '/gamedata/delete_game_data_list_by_json' // 선택한 티켓 삭제
export const CHANGE_GAME_STATE_TO_CANCEL_API = '/gamedata/change_game_data_status_to_cancel'  // 지점에서 처리상태 변경 (취소)
export const CHANGE_GAME_STATE_TO_PRINT_DONE_API = '/gamedata/change_game_data_status_to_print_done'  // 지점에서 처리상태 변경 (인쇄완료)
export const CHANGE_GAME_STATE_TO_PRINT_READY_API = '/gamedata/change_game_data_status_to_print_ready'  // 지점에서 처리상태 변경 (인쇄준비)
export const CHANGE_GAME_STATE_TO_ABORT_API = '/gamedata/change_game_data_status_to_request_abort'  // 지점에서 처리상태 변경 (보류)
export const CHANGE_GAME_STATE_TO_REQUEST_API = '/gamedata/change_game_data_status_to_request'  // 지점에서 처리상태 변경 (의뢰)
export const CHANGE_GAME_STATE_TO_REQUEST_CANCEL_API = '/gamedata/change_game_data_status_to_request_cancel'  // 지점에서 처리상태 변경 (의뢰취소)

// <-- 기타 --> //
export const CHECK_VERSION_API = '/generic/version'  // 현재버전 체크