import React, {useEffect, useState} from "react";
import {accountInfo} from "../../constants/constants";
import TicketCardModal from "../Modal/TicketCardModal";
import {getFetcher} from "../../utils/fetcher";
import {AGGREGATE_ITEM_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import neverLandUtils from "../../utils/NeverLandUtils";
import {REPORT_LOWER_TABLE_HEADER} from "../../constants/component_data";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill} from "react-icons/ri";

const ReportLowerCard = ({lowerTableBodyData, setAlignData}) => {
  const [ticketModalData, setTicketModalData] = useState({isOpen: false});
  const [contents, setContents] = useState([]);
  const [full, setFull] = useState([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [AllMiniCardMode, setAllMiniCardMode] = useState(true);
  const [showAlignButton, setShowAlignButton] = useState(false);
  const [currentAlignData, setCurrentAlignData] = useState(accountInfo.getAlignDataList("ReportLowerTable"));

  function makeAlignData(name) {
    // <-- 테이블 오름차순/내림차순 정렬 -->
    let prevData = currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      currentAlignData[name] = "asc";
    } else if (prevData === "asc") {
      currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      currentAlignData[name] = "-";
    }
    let newAlignData = {...currentAlignData};

    accountInfo.saveAlignData("ReportLowerTable", newAlignData);

    if (setAlignData)
      setAlignData(jsonDataToString(newAlignData));
    setCurrentAlignData(newAlignData);
  }

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    return Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
  }
  useEffect(() => {
    setFull([]);
    setContents(lowerTableBodyData);
  }, [lowerTableBodyData]);

  function _onChangeCardMode(data) {
    if (!full.includes(data.dataId)) {
      full.push(data.dataId);
      setFull(full);
    } else {
      let newFull = full.filter((d) => (d !== data.dataId));
      setFull(newFull);
    }
    setRefreshDate(new Date());
    return true;
  }

  function showTicketModal(requestNumber) {
    // <-- 폴더버튼 선택 시 티켓 모달띄우기 -->
    getFetcher().get(accountInfo.makeGetRequest(AGGREGATE_ITEM_API) + `&id=${requestNumber}`)
        .then(res => {
          const type = res.data.header.type;
          if (type === 'fail') {
            console.log('불러오기 실패');
          } else if (type === 'success') {
            setTicketModalData({isOpen: true, data: res.data.result.data});
          }
        }).catch(err => toast.error(err));
  }

  return (
      <div className="flex flex-col">
        {ticketModalData.isOpen &&
            <TicketCardModal ticketModalData={ticketModalData} setTicketModalData={setTicketModalData}/>}

        {showAlignButton &&
            <div className="flex flex-wrap gap-1 mx-2 md:mx-5 border-2 border-gray-200 p-1 rounded-lg">
              {REPORT_LOWER_TABLE_HEADER.map((data) => {
                return (
                    <div key={data.name}
                         className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-gray-600 rounded-lg">
                      {data.icon && (
                          <div className="flex items-center justify-center cursor-pointer"
                               onClick={() => makeAlignData(data.name)}>
                            <span>{data.name}</span>
                            {currentAlignData[data.name] !== undefined && currentAlignData[data.name] !== '-' ?
                                (currentAlignData[data.name] === "desc" ?
                                        <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/>
                                        : <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                                ) : <RiExpandUpDownFill className="h-4 w-4 text-gray-400 ml-1"/>
                            }
                          </div>
                      )}
                      {!data.icon && <p>{data.name}</p>}
                    </div>
                );
              })}
            </div>
        }

        <div className="mt-2 mx-3 flex flex-row">
          <button
              onClick={() => setShowAlignButton(prevState => !prevState)}
              className="md:mx-3 w-1/2 whitespace-nowrap rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm">
            {showAlignButton ? "정렬 숨기기" : "정렬 보이기"}
          </button>

          <button
              onClick={() => {
                setFull([]);
                setAllMiniCardMode(prevState => !prevState);
              }}
              className="md:mx-3 w-1/2 whitespace-nowrap rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ml-3">
            {AllMiniCardMode ? "모두 펼치기" : "모두 접기"}
          </button>
        </div>


        <div className="flex flex-wrap justify-center items-center gap-3">
          {/*<div className="grid grid-cols-1 md:grid-cols-2">*/}
          {contents.map((data) => {
            let isMini = !full.includes(data.dataId);

            if (isMini && AllMiniCardMode)
              return (
                  <_miniModeCard data={data} showTicketModal={showTicketModal}
                                 _onChangeCardMode={(data) => _onChangeCardMode(data)}/>
              );
            return (<_normalCard data={data} showTicketModal={showTicketModal}
                                 _onChangeCardMode={(data) => _onChangeCardMode(data)}/>);
          })}
        </div>
      </div>
  )
}
export default ReportLowerCard;


function sliceMemoText(memo, limitWidthSize) {
  // 메모 글자가 길어져서 폭 넘어가면 ... 으로 대체
  let fontFamily = 'NanumSquareNeo';
  let fontSize = 11;

  if (memo.length * (fontSize + 0.5) < limitWidthSize) {
    return memo;
  }
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = `${fontSize}px ${fontFamily}`

  let ellipsisWidth = context.measureText('..').width;
  let textWidth = context.measureText(memo).width;

  if (textWidth <= limitWidthSize) {
    return memo;
  }

  let truncatedText = memo;
  while (textWidth + ellipsisWidth > limitWidthSize && truncatedText.length > 0) {
    truncatedText = truncatedText.slice(0, -1);
    textWidth = context.measureText(truncatedText).width;
  }

  if (truncatedText.length > 0) {
    return truncatedText + '..';
  }
  canvas.remove();
  return '...';
}

const _miniModeCard = ({data, showTicketModal, _onChangeCardMode}) => {
  return (
      <div
          className="bg-white w-[23rem] my-1.5 whitespace-nowrap flex flex-col justify-center border-2 border-sky-900 rounded-lg md:mx-3 max-h-[9rem]"
      >
        <div className="flex flex-row w-full border-b border-sky-900 items-center justify-between">
          <div className="flex flex-row items-center"
               onClick={() => _onChangeCardMode(data)}
          >
            <p className="bg-sky-900 w-28 text-white px-3 py-2 text-center border-b border-gray-300 min-h-[3rem]">
              {accountInfo.isAdmin()
                  ? <><span>{data.requestNumber}</span><span
                      className="text-xs">{" (" + `${data.dataId}` + ")"}</span></>
                  : data.requestNumber}
            </p>
            {(data.memo && data.memo.trim().length > 0 && data.memo.trim() !== '-') ?
                <p className="pl-3 min-h-[3rem]"><span
                    className="text-xs">{accountInfo.isAdmin() ? data.storeName : data.userNickName}</span><br/><span>{sliceMemoText(data.memo, 90)}</span></p>
                : <p className="pl-3 min-h-[3rem] flex items-center">{accountInfo.isAdmin() ? data.storeName : data.userNickName}</p>
            }
          </div>
          <div className="flex flex-wrap items-center">
            <p className={neverLandUtils.classNames(data.gameResult === "낙첨" ? 'text-red-500' : (data.gameResult === "당첨" ? 'text-emerald-500'
                : 'text-gray-400'), 'font-semibold pl-2')}>
              {data.gameResult}
            </p>
            <button
                onClick={() => showTicketModal(data.dataId)}
                className="rounded-2xl w-14 py-1 bg-sky-600 text-white hover:bg-sky-500 mx-3">
              {data.folder}
            </button>
          </div>
        </div>

        <div className="flex flex-row border-b border-sky-900">
          <div className="flex flex-row w-3/5 items-center">
            <p className="bg-sky-900 text-white w-28 px-2 py-2 text-center">투표금액</p>
            <p className="pl-2">{Number(data.amount)?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-2/5 justify-start items-center">
            <p className="bg-sky-900 text-white w-20 py-2 text-center">선택배당</p>
            <p className="pl-2">{data.selectDividend}</p>
          </div>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center justify-end">
          <p className="pr-2 text-xs py-0.5">처리시각 : {data.progressDate.substring(0, 10) + " ( " + data.progressDate.substring(11, 19) + " ) "}</p>
        </div>
      </div>
  )
}

const _normalCard = ({data, showTicketModal, _onChangeCardMode}) => {
  return (
      <div
          className="bg-white w-[23rem] my-1.5 whitespace-nowrap flex flex-col justify-center border-2 border-sky-900 rounded-lg md:mx-3"
      >
        <div className="flex flex-row w-full border-b border-sky-900 items-center"
             onClick={() => _onChangeCardMode(data)}
        >
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">의뢰번호</p>
          <p className="pl-3">{accountInfo.isAdmin() ? (data.requestNumber + " (" + `${data.dataId}` + ")") : data.requestNumber}</p>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">처리시각</p>
          <p className="pl-3">{data.progressDate.substring(0, 10) + " ( " + data.progressDate.substring(11, 19) + " ) "}</p>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">지점</p>
          <p className="pl-3">{data.storeName}</p>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">고객닉네임</p>
          <p className="pl-3">{data.userNickName}</p>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">투표금액</p>
          <p className="pl-3">{Number(data.amount)?.toLocaleString()}</p>
        </div>

        <div className="flex flex-row border-b border-sky-900">
          <div className="flex flex-row w-1/2 items-center">
            <p className="bg-sky-900 text-white w-24 px-2 py-2 text-center border-b border-gray-300">선택배당</p>
            <p className="pl-3">{data.selectDividend}</p>
          </div>
          <div className="flex flex-row w-1/2 justify-start items-center">
            <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">결과배당</p>
            <p className="pl-3">{data.resultDividend}</p>
          </div>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">당첨액</p>
          <p className="pl-3">{Number(data.winningAmount)?.toLocaleString()}</p>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center justify-between">
          <div className="flex flex-row items-center">
            <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center border-b border-gray-300">상태</p>
            <p className={neverLandUtils.classNames(data.gameResult === "낙첨" ? 'text-red-500' : (data.gameResult === "당첨" ? 'text-emerald-500'
                : 'text-gray-400'), 'font-semibold pl-2')}>
              {data.gameResult}
            </p>
          </div>

          <button
              onClick={() => showTicketModal(data.dataId)}
              className="rounded-2xl w-14 py-1 bg-sky-600 text-white hover:bg-sky-500 mr-5">
            {data.folder}
          </button>
        </div>

        <div className="flex flex-row w-full border-b border-sky-900 items-center">
          <p className="bg-sky-900 text-white w-24 px-3 py-2 text-center">메모</p>
          <p className="pl-3">{data.memo}</p>
        </div>
      </div>
  )
}