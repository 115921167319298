import {REPORT_LOWER_TABLE_HEADER} from "../../constants/component_data";
import React, {useEffect, useState} from "react";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import TicketCardModal from "../Modal/TicketCardModal";
import {toast} from "react-toastify";
import fetcher, {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {AGGREGATE_ITEM_API} from "../../constants/api_constants";

const ReportLowerTable = ({lowerTableBodyData, setAlignData}) => {
  const [ticketModalData, setTicketModalData] = useState({isOpen: false});
  const [contents, setContents] = useState([]);
  const [currentAlignData, setCurrentAlignData] = useState(accountInfo.getAlignDataList("ReportLowerTable"));

  useEffect(() => {
    setContents(lowerTableBodyData);
  }, [lowerTableBodyData]);

  function showTicketModal(requestNumber) {
    // <-- 폴더버튼 선택 시 티켓 모달띄우기 -->
    getFetcher().get(accountInfo.makeGetRequest(AGGREGATE_ITEM_API) + `&id=${requestNumber}`)
        .then(res => {
          const type = res.data.header.type;
          if (type === 'fail') {
            console.log('불러오기 실패');
          } else if (type === 'success') {
            setTicketModalData({isOpen: true, data: res.data.result.data});
          }
        }).catch(err => toast.error(err));
  }

  function makeAlignData(name) {
    if(!currentAlignData.order){
      currentAlignData.order=[];
    }
    // <-- 테이블 오름차순/내림차순 정렬 -->
    let prevData = currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      currentAlignData[name] = "asc";
      if(!currentAlignData.order.includes(name))
        currentAlignData.order.push(name);
    } else if (prevData === "asc") {
      if(!currentAlignData.order.includes(name))
        currentAlignData.order.push(name);
      currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      if(currentAlignData.order.includes(name))
        currentAlignData.order=currentAlignData.order.filter((f)=>{ return  f!==name });
      currentAlignData[name] = "-";
    }
    let newAlignData = {...currentAlignData};


    accountInfo.saveAlignData("ReportLowerTable", newAlignData);

    if (setAlignData)
      setAlignData(jsonDataToString(newAlignData));
    setCurrentAlignData(newAlignData);
  }

  const jsonDataToString = (obj) => {
    // <-- JSON 데이터 받아서 파라미터에 넣을 형식으로 변환하기 -->
    let jsonString= Object.keys(obj).map(key => `${key}:${obj[key]}`).join('▼');
    console.log('makeAlignData->newAlignData',jsonString);
    return jsonString;

  }

  return (
      <>
        {ticketModalData.isOpen &&
            <TicketCardModal ticketModalData={ticketModalData} setTicketModalData={setTicketModalData}
                             ticketOrderType={{view: ["stateDetail"], options: ["fixed"]}}
            />}

        <div className="sm:min-h-[30rem]">
          <table className="mt-10 min-w-full divide-y divide-gray-300">
            <thead className={neverLandUtils.classNames(accountInfo.isStore() ? "top-0" : "top-14","sticky")}>
            <tr>
              {REPORT_LOWER_TABLE_HEADER.map((data) => {
                return (
                    <th scope="col"
                        key={data.name}
                        className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-stone-900 border-t">
                      {data.icon && (
                          <div className="flex items-center justify-center cursor-pointer"
                               onClick={() => makeAlignData(data.name)}>
                            {currentAlignData.order&&currentAlignData.order.includes(data.name)&&
                                <span className="inline-block w-4 h-4 bg-gray-200 text-center rounded-full text-gray-700 flex items-center justify-center
                                            text-xs mr-2">
                                    {currentAlignData.order.indexOf(data.name)+1}</span>}
                            <span>{data.name}</span>
                            {currentAlignData[data.name] !== undefined && currentAlignData[data.name] !== '-' ?
                                (currentAlignData[data.name] === "desc" ?
                                        <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/>
                                        : <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                                ) : <RiExpandUpDownFill className="h-4 w-4 text-stone-400 ml-1"/>
                            }
                          </div>
                      )}
                      {!data.icon && <p>{data.name}</p>}
                    </th>
                );
              })}
            </tr>
            </thead>
            {contents.map((data) => {
              return (
                  <tbody className="divide-y divide-stone-300 bg-white">
                    <tr key={data.requestNumber}
                        className="hover:bg-blue-50 whitespace-nowrap py-3 px-3 text-center text-sm font-medium text-stone-900">
                      <td className="py-2.5">
                        {accountInfo.isAdmin() ? (data.requestNumber + " (" + `${data.dataId}` + ")") : data.requestNumber}
                      </td>
                      <td>
                        {data.progressDate.substring(0,10) + " ( " + data.progressDate.substring(11,19) + " ) "}
                      </td>
                      {/*<td>*/}
                      {/*  {data.startDate}*/}
                      {/*</td>*/}
                      <td>
                        {data.storeName}
                      </td>
                      <td>
                        {data.userNickName}
                      </td>
                      <td>
                        {Number(data.amount)?.toLocaleString()}
                      </td>
                      <td>
                        {data.selectDividend}
                      </td>
                      <td>
                        {data.resultDividend}
                      </td>
                      <td>
                        {Number(data.winningAmount)?.toLocaleString()}
                      </td>
                      <td className={neverLandUtils.classNames(data.gameResult === "낙첨" ? 'text-red-500' : (data.gameResult === "당첨" ? 'text-emerald-500' : 'text-stone-400'),
                          'font-semibold')}>
                        {data.gameResult}
                      </td>
                      <td>
                        {data.memo}
                      </td>
                      <td>
                        <button
                            onClick={() => showTicketModal(data.dataId)}
                            className="rounded-2xl px-4 py-1 bg-sky-600 text-white hover:bg-sky-500">
                          {data.folder}
                        </button>
                      </td>
                    </tr>
                  </tbody>
              )
            })}

          </table>
        </div>
      </>
  )
}
export default ReportLowerTable;